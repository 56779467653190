import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import WheelFortune from 'wheel-fortune';
import TextSlicer from 'text-slicer';
import SmokeRing from './utils/SmokeRing';
import GainMessage from './utils/GainMessage';
import ModalManager from './utils/ModalManager';
import registrationForm from './utils/registration-form';

const App = {
  gainMessage: new GainMessage(),
  smokeRing: new SmokeRing(),
  textSlicer: new TextSlicer(),
  modalManager: new ModalManager(),
  registrationForm,

  registerGSAPPlugins() {
    gsap.registerPlugin(CustomEase);
    WheelFortune.registerGSAP(gsap, CustomEase);
  },

  initWheelFortune() {
    const firstSpin = () => {
      document.body.classList.add('first-spin-complete');
      this.gainMessage.slideOneToTwo();
    };

    const secondSpin = () => {
      document.body.classList.add('second-spin-complete');
      this.gainMessage.slideTwoToThree();
      setTimeout(() => this.modalManager.openModal(), 2750);
    };

    const spinStates = [
      { stopSegment: 4, callback: () => firstSpin() },
      { stopSegment: 1, callback: () => secondSpin() },
    ];

    this.wheelFortune = new WheelFortune({
      spinStates,
      rotationCount: 4,
      segmentCount: 6,
      wheelLibration: true,
    });

    this.wheelFortune.init();
  },

  initSmokeRing() {
    this.smokeRing.init({
      canvasEl: document.querySelector('#noisy-ring'),
      pageEl: document.querySelector('body'),
    });

    document.querySelector('.wheel__button').addEventListener('click', () => {
      this.smokeRing.flash();
    });
  },

  initTextSlicer() {
    document.querySelectorAll('.text-slicer').forEach((element) => {
      this.textSlicer = new TextSlicer({
        container: element,
        cssVariables: true,
        mode: 'chars',
      });

      this.textSlicer.init();
    });
  },

  async init() {
    this.registerGSAPPlugins();
    this.initWheelFortune();
    this.initSmokeRing();
    this.initTextSlicer();
    this.modalManager.init();
    await this.registrationForm.init();
  },
};

document.addEventListener('DOMContentLoaded', () => App.init());
