import TextFields from 'text-fields';
import SelectOptions from 'select-options';
import './formHandler'; /* TODO шифрование, форматирование телефона, проверка данных, генерация URL, обработка формы */

const registrationForm = {
  selectOptions: new SelectOptions(),
  textFields: new TextFields(),

  promoCodeHandler({ hidingEl, inputName }) {
    const hidingElement = document.querySelector(hidingEl);
    const inputElement = document.querySelector(`input[name="${inputName}"]`);

    if (hidingElement && inputElement) {
      hidingElement.addEventListener('click', () => {
        hidingElement.remove();
        inputElement.focus();
      });
    }
  },

  async init() {
    await this.textFields.init();
    this.selectOptions.init();
    this.promoCodeHandler({ hidingEl: '.promo-code', inputName: 'promocode' });
  },
};

export default registrationForm;
