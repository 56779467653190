import vertexShader from '../shaders/vertex.glsl';
import fragmentShader from '../shaders/fragment.glsl';

class SmokeRing {
  #canvasEl;

  #gl;

  #mouse;

  #clickTime;

  #wasClicked;

  #uniforms;

  #mouseThreshold;

  constructor() {
    this.#canvasEl = null;
    this.#gl = null;
    this.#mouse = {
      x: -0.5 * window.innerWidth,
      y: 0.5 * window.innerHeight,
      tX: -0.5 * window.innerWidth,
      tY: 0.5 * window.innerHeight,
    };
    this.#clickTime = 0;
    this.#wasClicked = false;
    this.#uniforms = {
      timeLocation: null,
      ratioLocation: null,
      clickTimeLocation: null,
      pointerLocation: null,
      clickLocation: null,
    };
    this.#mouseThreshold = 0.6;
  }

  init({ canvasEl, pageEl }) {
    this.#canvasEl = canvasEl;
    this.pageEl = pageEl;
    this.#gl = this.#initShader();
    this.render();

    window.addEventListener('resize', () => this.#resizeCanvas());
    this.#resizeCanvas();

    window.addEventListener('mousemove', (e) => {
      this.#updateMousePosition(e.pageX, e.pageY);
    });
  }

  #updateMousePosition(eX, eY) {
    this.#mouse.tX = eX;
    this.#mouse.tY = eY;
  }

  #initShader() {
    const vsSource = vertexShader;
    const fsSource = fragmentShader;
    const gl = this.#canvasEl.getContext('webgl', { alpha: true }) || this.#canvasEl.getContext('experimental-webgl', { alpha: true });

    if (!gl) {
      return null;
    }

    const createShader = (glContext, sourceCode, type) => {
      const shader = glContext.createShader(type);

      glContext.shaderSource(shader, sourceCode);
      glContext.compileShader(shader);

      if (!glContext.getShaderParameter(shader, glContext.COMPILE_STATUS)) {
        glContext.deleteShader(shader);

        return null;
      }

      return shader;
    };

    const vertexShaderCompiled = createShader(gl, vsSource, gl.VERTEX_SHADER);
    const fragmentShaderCompiled = createShader(gl, fsSource, gl.FRAGMENT_SHADER);

    if (!vertexShaderCompiled || !fragmentShaderCompiled) {
      return null;
    }

    const shaderProgram = SmokeRing.createShaderProgram(gl, vertexShaderCompiled, fragmentShaderCompiled);

    if (!shaderProgram) {
      return null;
    }

    const vertices = new Float32Array([-1.0, -1.0, 1.0, -1.0, -1.0, 1.0, 1.0, 1.0]);
    const vertexBuffer = gl.createBuffer();

    gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);
    gl.useProgram(shaderProgram);

    const positionLocation = gl.getAttribLocation(shaderProgram, 'a_position');

    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 0, 0);

    this.#uniforms = {
      timeLocation: gl.getUniformLocation(shaderProgram, 'u_time'),
      ratioLocation: gl.getUniformLocation(shaderProgram, 'u_ratio'),
      clickTimeLocation: gl.getUniformLocation(shaderProgram, 'u_click_time'),
      pointerLocation: gl.getUniformLocation(shaderProgram, 'u_pointer'),
      clickLocation: gl.getUniformLocation(shaderProgram, 'u_click'),
    };

    return gl;
  }

  static createShaderProgram(gl, vertexShaderCompiled, fragmentShaderCompiled) {
    const program = gl.createProgram();

    gl.attachShader(program, vertexShaderCompiled);
    gl.attachShader(program, fragmentShaderCompiled);
    gl.linkProgram(program);

    if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
      return null;
    }

    return program;
  }

  render() {
    requestAnimationFrame(() => this.render());

    const currentTime = performance.now();

    this.#gl.uniform1f(this.#uniforms.timeLocation, currentTime);

    if (this.#wasClicked) {
      this.#gl.uniform1f(this.#uniforms.clickTimeLocation, currentTime - this.#clickTime);
    }

    this.#gl.clearColor(0.0, 0.0, 0.0, 0.0);
    this.#gl.clear(this.#gl.COLOR_BUFFER_BIT);
    this.#gl.drawArrays(this.#gl.TRIANGLE_STRIP, 0, 4);

    this.#mouse.x += (this.#mouse.tX - this.#mouse.x) * this.#mouseThreshold;
    this.#mouse.y += (this.#mouse.tY - this.#mouse.y) * this.#mouseThreshold;

    this.#gl.uniform2f(this.#uniforms.pointerLocation, this.#mouse.x / window.innerWidth, 1.0 - this.#mouse.y / window.innerHeight);
  }

  #resizeCanvas() {
    this.#canvasEl.width = window.innerWidth * devicePixelRatio;
    this.#canvasEl.height = window.innerHeight * devicePixelRatio;
    this.#gl.viewport(0, 0, this.#canvasEl.width, this.#canvasEl.height);
    this.#gl.uniform1f(this.#uniforms.ratioLocation, window.innerWidth / window.innerHeight);
  }

  flash() {
    this.#gl.uniform2f(this.#uniforms.clickLocation, this.#mouse.x / window.innerWidth, 1.0 - this.#mouse.y / window.innerHeight);
    this.#wasClicked = true;
    this.#clickTime = performance.now();
  }
}

export default SmokeRing;
