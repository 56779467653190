class GainMessage {
  #gainMessageOne;

  #gainMessageTwo;

  #gainMessageThree;

  constructor() {
    this.#gainMessageOne = document.querySelector('.gain-message-one');
    this.#gainMessageTwo = document.querySelector('.gain-message-two');
    this.#gainMessageThree = document.querySelector('.gain-message-three');
  }

  // eslint-disable-next-line class-methods-use-this
  delay(duration, callback) {
    let start = null;

    const step = (timestamp) => {
      if (!start) start = timestamp;

      const progress = timestamp - start;

      if (progress >= duration) {
        callback();
      } else {
        requestAnimationFrame(step);
      }
    };

    requestAnimationFrame(step);
  }

  slideOneToTwo() {
    this.#gainMessageOne.classList.add('slide-in');

    this.delay(300, () => {
      this.#gainMessageTwo.classList.remove('slide-null');
      this.#gainMessageTwo.classList.add('slide-out');
    });
  }

  slideTwoToThree() {
    this.#gainMessageTwo.classList.remove('slide-out');
    this.#gainMessageTwo.classList.add('slide-in');

    this.delay(300, () => {
      this.#gainMessageThree.classList.remove('slide-null');
      this.#gainMessageThree.classList.add('slide-out');
    });
  }
}

export default GainMessage;
