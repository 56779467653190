class ModalManager {
  #modal;

  #modalClose;

  #modalBackdrop;

  constructor() {
    this.#modal = document.querySelector('.modal');
    this.#modalClose = document.querySelector('.modal-close');
    this.#modalBackdrop = document.querySelector('.modal__backdrop');
  }

  openModal() {
    this.#modal.classList.remove('modal--out');
    this.#modal.classList.add('modal--in');
  }

  closeModal() {
    this.#modal.classList.remove('modal--in');
    this.#modal.classList.add('modal--out');
  }

  init() {
    this.#modalClose.addEventListener('click', () => this.closeModal());
    this.#modalBackdrop.addEventListener('click', () => this.closeModal());
  }
}

export default ModalManager;
