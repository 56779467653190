/** Constants */
import countryCodes from './countryCodes';

const PARTNER_ID = '';
const BASE_URL = 'https://championwins.lol';
const KEY = {
  kty: 'RSA',
  use: 'enc',
  alg: 'RSA-OAEP-256',
  kid: '43d6136c-c357-4fe6-8ae3-cce646f97c86',
  n: 'qdjMZx2Zc8tH8fFgY34e6KjQ7rklueW16zO57_GZ-WbPnomuKM9pf7YfB6r4HApWoYzJvJRWBjHjkQ7cynUfVXC_JM72AbJHK4Gs6ojyImGFPCOx6Fi0s6gExzJFiNjFiEmzSL1ZULI3vjk4PaNz1gfOSAZxVg0Ucw8k8hdqY9HA7FF3r7Rx1glB1Fs17tSMNb-ri1rfGU_ZvnYY3z8eoNP2KatJYFb-CKl1tfpkisbBYYz0Jxcvmfr3EwqrRoWFCDxBm3wHskcUCgQZZRJPNOl4ekQ4GlHTSyksk2CdeV0jhcIqyi27ZHm_Jn7uj_kkbu6QkT6MUTyWt8DXL7kg-UG3FpCAvRbBAIGLJ9T5BmtM7-hyDehRWP1EaWFJh8ji1Bg9D-IHDH5J_MraiGXNlcKAszEF9F0TeulXf0iAmOSqGdijVhlUW2gwO7SjhjdMvxRW2VXc2jtZH3dKP5OwP_BtDG9qhdQH-QnYBWmD19sPSJ996tXoAndW9ovymD666lgmtzOWoeGHE4U0X0L9KW9D2SI7KZQFaJsRewsCHr1ucZpSbswu18s3NSkZ2POMtuIYOgiLIu5mifB_Seb46GvdlR1wfUSbU7puAAudaqaKhb-UTgTx_TQVWLPQkzYGf73visur_6M0zSxfp2QEJXRnLLIvqbInOdwKQagX6Hs',
  e: 'AQAB',
};

const ENCRYPT_ALGORITHM = {
  name: 'RSA-OAEP',
  modulusLength: 4096,
  publicExponent: new Uint8Array([1, 0, 1]),
  hash: 'SHA-256',
};

/* Encryption */
function arrayBufferToBase64(arrayBuffer) {
  const byteArray = new Uint8Array(arrayBuffer);
  let byteString = '';
  for (let i = 0; i < byteArray.byteLength; i++) {
    byteString += String.fromCharCode(byteArray[i]);
  }
  return window.btoa(byteString);
}

async function encryptMessage(message) {
  const importedKey = await crypto.subtle.importKey(
    'jwk',
    KEY,
    ENCRYPT_ALGORITHM,
    true,
    ['encrypt'],
  );

  const encodeMessage = new TextEncoder().encode(message);

  const encryptedDataArrayBuffer = await crypto.subtle.encrypt(
    ENCRYPT_ALGORITHM,
    importedKey,
    encodeMessage,
  );

  return arrayBufferToBase64(encryptedDataArrayBuffer);
}

// Submit
async function onSubmitForm(e) {
  e.preventDefault();
  const isValid = validateFormFields();

  if (!isValid) {
    return;
  }

  const formValues = {};
  const { elements } = this;
  Array.from(elements).forEach((element) => {
    const { name, type } = element;
    const value = type === 'checkbox' ? element.checked : element.value;
    if (value !== '') {
      Object.assign(formValues, { [name]: value });
    }
  });

  const url = await prepareUrlWithSearchString(formValues);
  window.location.assign(url);
}

// Validation
function validateFormFields() {
  return validateLogin() && validatePassword();
}

function validateLogin() {
  const loginField = document.querySelector('input[name=login]');
  const isPhoneType = checkIsPhoneType(loginField.value);

  if (isPhoneType) {
    // Длина очищенного номера телефона формата +71234567890
    const validPhoneLength = 12;

    if (loginField.value.length !== validPhoneLength) {
      loginField.setCustomValidity('Неверный формат номера телефона');
      loginField.reportValidity();
      loginField.setAttribute('required', 'true');
      loginField.closest('.input').classList.add('input--error');
      return false;
    }

    loginField.setCustomValidity('');
    loginField.removeAttribute('required');
    loginField.closest('.input').classList.remove('input--error');
    return true;
  }

  const emailRegExp = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g;

  const isValidEmail = (emailRegExp).test(loginField.value);
  if (isValidEmail) {
    return true;
  }

  loginField.setCustomValidity('Неверный формат email');
  loginField.reportValidity();
  return false;
}

function validatePassword() {
  const passwordField = document.querySelector('input[name=password]');
  const passwordValue = passwordField.value;
  const minLength = 8;
  const hasDigit = /\d/.test(passwordValue);

  if (passwordValue.length < minLength || !hasDigit) {
    passwordField.setCustomValidity('В пароле должно быть не менее 8 символов и должна быть цифра');
    passwordField.reportValidity();
    passwordField.setAttribute('required', 'true');
    passwordField.closest('.input').classList.add('input--error');
    return false;
  }

  passwordField.setCustomValidity('');
  passwordField.removeAttribute('required');
  passwordField.closest('.input').classList.remove('input--error');
  return true;
}

// Helpers
async function prepareUrlWithSearchString(formValues) {
  // const rid = crypto.randomUUID();
  const extendedData = {
    ...formValues,
    time: Date.now(),
    // partner_id: PARTNER_ID,
    // rid,
    rid: crypto.randomUUID(),
  };

  const encryptedUserData = await encryptMessage(JSON.stringify(extendedData));

  const existingSearchParams = new URLSearchParams(document.location.search);

  const params = {
    ...Object.fromEntries(existingSearchParams),
    autoreg: true,
    version: 1,
    key: crypto.randomUUID(),
    // userData: encryptedUserData
    userData: encryptedUserData,
    qs_referrer: document.location.href,
  };

  const searchString = new URLSearchParams(Object.entries(params)).toString();

  const url = new URL('/registration', BASE_URL);
  url.search = searchString;

  return url.toString();
}

function checkIsPhoneType(value) {
  return (/^[+() \-0-9]*$/).test(value);
}

function convertPhoneToInternationalFormat() {
  if (!checkIsPhoneType(this.value)) {
    return;
  }

  const phone = this.value.replaceAll(/[^0-9]/g, '');
  const countryCode = getCountryCode(phone);

  if ((countryCode === 'ru' || countryCode === 'kz')) {
    this.value = phone.replace(/^7|8/, '+7');
  } else {
    this.value = phone;
  }
}

function getCountryCode(phone) {
  if (!phone) {
    return;
  }

  const phoneNumbers = phone.replace(/[^0-9]/g, '');

  for (let i = phoneNumbers.length; i > 0; i--) {
    const phoneCode = phone.slice(0, i);
    const countryCode = countryCodes[phoneCode];
    if (countryCode) {
      if (countryCode === 'us') {
        return 'ca';
      }
      return countryCode;
    }
  }
}

function addJsInForm() {
  window.addEventListener('load', () => {
    const form = document.querySelector('#registration-form');
    form.addEventListener('submit', onSubmitForm);

    const loginField = document.querySelector('input[name=login]');
    loginField.addEventListener('blur', convertPhoneToInternationalFormat);
    loginField.addEventListener('input', function () {
      this.setCustomValidity('');
    });

    const passwordField = document.querySelector('input[name=password]');
    passwordField.addEventListener('input', function () {
      this.setCustomValidity('');
      passwordField.closest('.input').classList.remove('input--error');
    });
  });
}

addJsInForm();
